import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import Seo from "../components/seo"
import { rhythm } from "../utils/typography"
import { useInView } from "react-intersection-observer"

const BlogIndex = ({ data, setTransparentNav }) => {
  const posts = data.allMarkdownRemark.edges
  const [ref, inView] = useInView({
    threshold: 0.75,
    initialInView: true,
  })
  useEffect(() => {
    if (inView) {
      setTransparentNav(true)
    } else {
      setTransparentNav(false)
    }
  }, [inView])
  return (
    <>
      <Seo
        title="Blog"
        description="What's happening at Integrant Labs? Find out more in our blog posts."
      />
      <div className={`blog`}>
        <div className={`page-heading`} ref={ref}>
          <div className={`overlay`}>
            <h1>What are we up to?</h1>
          </div>
        </div>
        <div className={`page-content`}>
          <div className={`text-box`}>
            <h2>Recent posts:</h2>
          </div>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <div
                style={{
                  width: '80%',
                  paddingBottom:'10px'
                }}
                >
              <Link
                key={node._id}
                to={node.fields.slug}
                onClick={() => setTransparentNav(false)}
                style={{paddingBottom:'10px'}}
              >
                <article
                  key={node.fields.slug}
                >
                  <header >
                    <h3
                      className={`blog-link`}
                      style={{
                        marginBottom: rhythm(1 / 4),
                      }}
                    >
                      {title}
                    </h3>
                    <small>{node.frontmatter.date}</small>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: node.frontmatter.description || node.excerpt,
                      }}
                    />
                  </section>
                </article>
              </Link>
              </div>
            )
          })}
        </div>
        {/* TEMP: */}
        <div className={`page-content`}></div>
        <div className={`page-content`}></div>
        <div className={`page-content`}></div>
        <div className={`page-content`}></div>
        {/* --------- */}
      </div>
    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
